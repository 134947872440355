import { FETCH_MONITORING_ACTION, MonitoringAction } from '../../../components/administrator/monitoring/fetchMonitoring';
import { IInvestorsMassAction, IInvestorsMassAttach } from '../../../types/investorTypes';
import { IBalanceOperation, ILoadOrders, IMonitoringResponse, IOrdersCompare, IOrdersRedistribute, IOrdersReload, IOrderReopen, IParametersToCheck, IParametersToFix, IPerformanceFeeCharge, ISendReport, IPositionCheckFix, IInvestorsCheck } from '../../../types/maintenanceTypes';
import { FETCH_MASS_ATTACH, FETCH_MASS_DEACTIVATION, FETCH_MASS_DETACH, MassAtachAction, MassDeactivationAction, MassDetachAction } from '../../actions/investor/massAttachDetachInvestors';
import { AccountParametersAction, FETCH_ACCOUNT_PARAMETERS } from '../../actions/maintenance/fetchAccountsParameters';
import { EditBalanceOperationAction, FETCH_EDIT_BALANCE_OPERATION } from '../../actions/maintenance/fetchEditBalanceOperation';
import { InvestorsParametersAction, FETCH_INVESTORS_CHECK_PARAMETERS } from '../../actions/maintenance/fetchInvestorsCheck';
import { FETCH_LOAD_ORDERS, LoadOrdersAction } from '../../actions/maintenance/fetchLoadOrdersByTickets';
import { FETCH_ORDERS_COMPARE, OrdersCompareAction } from '../../actions/maintenance/fetchOrdersCompare';
import { FETCH_ORDERS_REDISTRIBUTE, OrdersRedistributeAction } from '../../actions/maintenance/fetchOrdersRedistribute';
import { FETCH_ORDERS_RELOAD, OrdersReloadAction } from '../../actions/maintenance/fetchOrdersReload';
import { FETCH_ORDERS_REOPEN, OrderReopenAction } from '../../actions/maintenance/fetchOrdersReopen';
import { FETCH_PERFORMANCE_FEE_CHARGE, PerformanceFeeChargeAction } from '../../actions/maintenance/fetchPerformanceFeeCharge';
import { FETCH_POSITION_CHECK_FIX, PositionCheckFixAction } from '../../actions/maintenance/fetchPositionCheckFix';
import { FETCH_SEND_REPORT, SendReportAction } from '../../actions/maintenance/fetchSendReport';

export interface SendReportState {
  sendReport: ISendReport;
  loadOrders: ILoadOrders;
  ordersReload: IOrdersReload;
  orderReopen: IOrderReopen;
  ordersCompare: IOrdersCompare;
  balanceOperation: IBalanceOperation;
  ordersRedistribute: IOrdersRedistribute;
  parametersAccounts: IParametersToCheck | IParametersToFix;
  parametersInvestors: IInvestorsCheck;
  positionCheckFix: IPositionCheckFix;
  performanceFeeCharge: IPerformanceFeeCharge;
  parameters: IMonitoringResponse;
  massAttach: IInvestorsMassAttach;
  massDetach: IInvestorsMassAction;
  massDeactivation: IInvestorsMassAction;
}

const initialState: SendReportState = {
  sendReport: {} as ISendReport,
  loadOrders: {} as ILoadOrders,
  ordersReload: {} as IOrdersReload,
  orderReopen:  {} as IOrderReopen,
  ordersCompare: {} as IOrdersCompare,
  balanceOperation: {} as IBalanceOperation,
  ordersRedistribute: {} as IOrdersRedistribute,
  parametersAccounts: {} as IParametersToCheck | IParametersToFix,
  parametersInvestors: {} as IInvestorsCheck,
  positionCheckFix: {} as IPositionCheckFix,
  performanceFeeCharge: {} as IPerformanceFeeCharge,
  parameters: {} as IMonitoringResponse,
  massAttach: {} as IInvestorsMassAttach,
  massDetach: {} as IInvestorsMassAction,
  massDeactivation: {} as IInvestorsMassAction
}


type RequestAction = SendReportAction
  & LoadOrdersAction
  & OrdersReloadAction
  & OrderReopenAction
  & OrdersCompareAction
  & EditBalanceOperationAction
  & OrdersRedistributeAction
  & AccountParametersAction
  & InvestorsParametersAction
  & PositionCheckFixAction
  & PerformanceFeeChargeAction
  & MonitoringAction
  & MassAtachAction
  & MassDetachAction
  & MassDeactivationAction

const reportReducer = (
  state: SendReportState = initialState,
  action: RequestAction
): SendReportState => {
  switch (action.type) {
    case FETCH_SEND_REPORT:
      return {
        ...state,
        sendReport: action.sendReport
      }
    case FETCH_LOAD_ORDERS:
      return {
        ...state,
        loadOrders: action.loadOrders
      };
      case FETCH_ORDERS_RELOAD:
        return {
          ...state,
          ordersReload: action.ordersReload
        };
        case FETCH_ORDERS_REOPEN:
          return {
            ...state,
            orderReopen: action.orderReopen
          };
    case FETCH_ORDERS_COMPARE:
      return {
        ...state,
        ordersCompare: action.ordersCompare
      };
    case FETCH_EDIT_BALANCE_OPERATION:
      return {
        ...state,
        balanceOperation: action.balanceOperation
      };
    case FETCH_ORDERS_REDISTRIBUTE:
      return {
        ...state,
        ordersRedistribute: action.ordersRedistribute
      };
    case FETCH_ACCOUNT_PARAMETERS:
      return {
        ...state,
        parametersAccounts: action.parametersAccounts
      };
    case FETCH_INVESTORS_CHECK_PARAMETERS:
      return {
        ...state,
        parametersInvestors: action.parametersInvestors
      };
    case FETCH_POSITION_CHECK_FIX:
      return {
        ...state,
        positionCheckFix: action.positionCheckFix
      };
    case FETCH_PERFORMANCE_FEE_CHARGE:
      return {
        ...state,
        performanceFeeCharge: action.performanceFeeCharge
      };
    case FETCH_MONITORING_ACTION:
      return {
        ...state,
        parameters: action.parameters
      };
    case FETCH_MASS_ATTACH:
      return {
        ...state,
        massAttach: action.massAttach
      };
    case FETCH_MASS_DETACH:
      return {
        ...state,
        massDetach: action.massDetach
      };
      case FETCH_MASS_DEACTIVATION:
        return {
          ...state,
          massDeactivation: action.massDeactivation
        };
    default:
      return state;
  }
};

export default reportReducer;
