/* eslint-disable no-underscore-dangle */
import * as Yup from 'yup';
import { IntlShape } from 'react-intl';

export class AccountModificationSchemas {
  public static readonly VALIDATOR_REQUIRED_KEY = 'validators.error.cannotBeEmpty'

  public static readonly PASSWORD_MIN_LENGTH = 8
  public static readonly PASSWORD_MAX_LENGTH = 16
  public static readonly TEXT_FIELD_MAX_LENGTH = 100
  public static readonly MIN_INVESTMENTS_MAX_LENGTH = 10

  public static readonly PASSWORD_INVALID_SYMBOLS_REGEXP = /^[A-Za-z0-9!@#$*%`~^()\-_=+{}[\];:'"\\|/?<>.,&]+$/
  public static readonly PASSWORD_ONE_UPPERCASE_REGEXP = /^(?=.*[A-Z]).+$/
  public static readonly PASSWORD_ONE_LOWERCASE_REGEXP = /^(?=.*[a-z]).+$/
  public static readonly PASSWORD_ONE_NUMBER_REGEXP = /^(?=.*[0-9]).+$/
  public static readonly PASSWORD_ONE_SPECIAL_CHARACTER_REGEXP = /^(?=.*[!@#$*%`~^()\-_=+{}[\];:'"\\|/?<>.,&]).+$/

  public static readonly USERNAME_REGEXP = /^[A-Za-z0-9_.@-]+?$/
  public static readonly NAME_REGEXP = /^([\p{L}\p{N}\p{P}\p{S}]+ )+[\p{L}\p{N}\p{P}\p{S}]+$|^[\p{L}\p{N}\p{P}\p{S}]+$/u
  public static readonly EMAIL_REGEXP = /^[A-Za-zА-Яа-я0-9._%+-]+@[A-Za-zА-Яа-я0-9._%+-]+\.[A-Za-zА-Яа-я0-9]{1,100}$/
  public static readonly COUNTRY_REGEXP = /^[A-Za-z- ]+$/
  public static readonly PHONE_REGEXP = /^[A-Za-zА-Яа-я0-9\s()+-]*$/
  public static readonly LINK_TOKEN_REGEXP = /^[A-Za-zА-Яа-я0-9_-]+$/
  public static readonly LOGIN_REGEXP = /^[0-9]+$/
  public static readonly HIGH_WATER_MARK_REGEXP = /^([0-9-]+(\.\d{1,2})?)$/
  public static readonly INVESTMENTS_REGEXP = /^[0-9]*$/
  public static readonly MT_USERNAME_REGEXP = /^(?!0)\d*$/

  private readonly _intl: IntlShape

  constructor(intl: IntlShape) {
    this._intl = intl
  }

  public get password() {
    return Yup.string()
      .required(this._intl.formatMessage({id: AccountModificationSchemas.VALIDATOR_REQUIRED_KEY}))
      .min(AccountModificationSchemas.PASSWORD_MIN_LENGTH, this._intl.formatMessage({id: 'validators.error.passwordMinLength'}))
      .max(AccountModificationSchemas.PASSWORD_MAX_LENGTH, this._intl.formatMessage({id: 'validators.error.passwordMaxLength'}))
      .matches(AccountModificationSchemas.PASSWORD_INVALID_SYMBOLS_REGEXP, this._intl.formatMessage({id: 'validators.error.passwordInvalidSymbols'}))
      .matches(AccountModificationSchemas.PASSWORD_ONE_UPPERCASE_REGEXP, this._intl.formatMessage({id: 'validators.error.passwordOneUppercaseCharacter'}))
      .matches(AccountModificationSchemas.PASSWORD_ONE_LOWERCASE_REGEXP, this._intl.formatMessage({id: 'validators.error.passwordOneLowercaseCharacter'}))
      .matches(AccountModificationSchemas.PASSWORD_ONE_NUMBER_REGEXP, this._intl.formatMessage({id: 'validators.error.passwordOneNumber'}))
      .matches(AccountModificationSchemas.PASSWORD_ONE_SPECIAL_CHARACTER_REGEXP, this._intl.formatMessage({id: 'validators.error.passwordOneSpecialCharacter'}))
  }

  public get passwordNotRequired() {
    const valueIsEmpty = (value: string) => !value || value.length === 0;

    return Yup.string()
      .nullable()
      .test('password-min-length', this._intl.formatMessage({id: 'validators.error.passwordMinLength'}),
        (value: string) => valueIsEmpty(value) || value.length >= AccountModificationSchemas.PASSWORD_MIN_LENGTH )
      .test('password-max-length', this._intl.formatMessage({id: 'validators.error.passwordMaxLength'}),
        (value: string) => valueIsEmpty(value) || value.length <= AccountModificationSchemas.PASSWORD_MAX_LENGTH )
      .test('password-invalid-symbols', this._intl.formatMessage({id: 'validators.error.passwordInvalidSymbols'}),
        (value: string) => valueIsEmpty(value) || AccountModificationSchemas.PASSWORD_INVALID_SYMBOLS_REGEXP.test(value))
      .test('password-one-uppercase-character', this._intl.formatMessage({id: 'validators.error.passwordOneUppercaseCharacter'}),
        (value: string) => valueIsEmpty(value) || AccountModificationSchemas.PASSWORD_ONE_UPPERCASE_REGEXP.test(value))
      .test('password-one-lowercase-character', this._intl.formatMessage({id: 'validators.error.passwordOneLowercaseCharacter'}),
        (value: string) => valueIsEmpty(value) || AccountModificationSchemas.PASSWORD_ONE_LOWERCASE_REGEXP.test(value))
      .test('password-one-digit', this._intl.formatMessage({id: 'validators.error.passwordOneNumber'}),
        (value: string) => valueIsEmpty(value) || AccountModificationSchemas.PASSWORD_ONE_NUMBER_REGEXP.test(value))
      .test('password-one-special-character', this._intl.formatMessage({id: 'validators.error.passwordOneSpecialCharacter'}),
        (value: string) => valueIsEmpty(value) || AccountModificationSchemas.PASSWORD_ONE_SPECIAL_CHARACTER_REGEXP.test(value))
  }

  public get name() {
    return Yup.string()
      .required(this._intl.formatMessage({id: AccountModificationSchemas.VALIDATOR_REQUIRED_KEY}))
      .matches(AccountModificationSchemas.NAME_REGEXP, this._intl.formatMessage({id: 'validators.error.invalidName'}))
  }

  public get username() {
    return Yup.string()
      .required(this._intl.formatMessage({id: AccountModificationSchemas.VALIDATOR_REQUIRED_KEY}))
      .max(AccountModificationSchemas.TEXT_FIELD_MAX_LENGTH, this._intl.formatMessage({id: 'validators.error.name'}))
      .matches(AccountModificationSchemas.USERNAME_REGEXP, this._intl.formatMessage({id: 'validators.error.username'}))
  }

  public get email() {
    return Yup.string()
      .required(this._intl.formatMessage({id: AccountModificationSchemas.VALIDATOR_REQUIRED_KEY}))
      .matches(AccountModificationSchemas.EMAIL_REGEXP, this._intl.formatMessage({id: 'validators.error.email'}))
  }

  public get country() {
    return Yup.string()
      .max(AccountModificationSchemas.TEXT_FIELD_MAX_LENGTH, this._intl.formatMessage({id: 'validators.error.maxLength'}))
      .matches(AccountModificationSchemas.COUNTRY_REGEXP, this._intl.formatMessage({id: 'validators.error.mustBeLetterDashSpace'}))
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
  }

  public get phone() {
    return Yup.string()
      .max(AccountModificationSchemas.TEXT_FIELD_MAX_LENGTH, this._intl.formatMessage({id: 'validators.error.maxLength'}))
      .matches(AccountModificationSchemas.PHONE_REGEXP, this._intl.formatMessage({id: 'validators.error.mustBeLetterNumberDashPlus'}))
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
  }

  public get linkToken() {
    return Yup.string()
      .matches(AccountModificationSchemas.LINK_TOKEN_REGEXP, this._intl.formatMessage({id: 'validators.error.mustBeLetterNumberDashUnderscore'}))
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
  }

  public get login() {
    return Yup.string()
      .matches(AccountModificationSchemas.LOGIN_REGEXP, this._intl.formatMessage({id: 'validators.error.login'}))
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
  }

  public get highWaterMark() {
    return Yup.string()
      .matches(AccountModificationSchemas.HIGH_WATER_MARK_REGEXP, this._intl.formatMessage({id: 'validators.error.mustBeLetterNumberDashUnderscore'}))
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
  }

  public get minInvestments() {
    return Yup.string()
      .max(AccountModificationSchemas.MIN_INVESTMENTS_MAX_LENGTH, this._intl.formatMessage({id: 'validators.error.minInvestment'}))
      .matches(AccountModificationSchemas.INVESTMENTS_REGEXP, this._intl.formatMessage({id: 'validators.error.mustBeNumber'}))
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
  }

  public get minInvestmentsPositiveNumber() {
    return Yup.number()
      .moreThan(-1, this._intl.formatMessage({ id: 'validators.error.mustBePositive' }))
      .typeError(this._intl.formatMessage({ id: 'validators.error.mustBeNumber' }))
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
  }

  public get investments() {
    return Yup.string()
      .matches(AccountModificationSchemas.INVESTMENTS_REGEXP, this._intl.formatMessage({id: 'validators.error.mustBeNumber'}))
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
  }

  public get loadHistoryMonthCount() {
    return Yup.number()
      .required(this._intl.formatMessage({id: AccountModificationSchemas.VALIDATOR_REQUIRED_KEY}))
      .typeError(this._intl.formatMessage({id: 'validators.error.loadHistoryMonth'}))
      .integer(this._intl.formatMessage({id: 'validators.error.loadHistoryMonth'}))
      .min(0, this._intl.formatMessage({id: 'validators.error.loadHistoryMonth'}))
      .max(100, this._intl.formatMessage({id: 'validators.error.loadHistoryMonth'}))
  }
}
