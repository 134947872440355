/* eslint-disable no-nested-ternary */
import { Dispatch } from 'redux';
import LocalStorageUtils from '../../../utils/local-storage';
import Errors from '../../../shared/validation/errors';
import setLoadingStatus, { setLoadingStatusConnectedInvestors } from '../setLoadingStatus';
import headersBuilder, { Method } from '../../../utils/request';
import { Investor } from '../../../types/investorTypes';
import { PaginationInfo } from '../../../types/sharedTypes';

export const FETCH_INVESTORS = 'FETCH_INVESTORS';
export const RESET_DATA_INVESTORS = 'RESET_DATA_INVESTORS';
export const UPDATE_INVESTORS = 'UPDATE_INVESTORS';
export const SELECTED_LINKED_ACCOUNT = 'SELECTED_LINKED_ACCOUNT';

export type FetchInvestorsAction = {
  type: string;
  investors: Array<Investor>;
  paginationInfo: PaginationInfo;
};

export type selectedLinkedAccountAction = {
  type: string;
  isSelected: boolean;
}

export const selectedLinkedAccountAC = (isSelected: boolean): selectedLinkedAccountAction => {
  return {
    type: SELECTED_LINKED_ACCOUNT,
    isSelected
  };
};

export const fetchInvestors = (
  investors: Array<Investor>,
  paginationInfo: PaginationInfo,
): FetchInvestorsAction => {
  return {
    type: FETCH_INVESTORS,
    investors,
    paginationInfo,
  };
};


export const resetDataInvestorsAC = () => ({ type: RESET_DATA_INVESTORS });

export const updateInvestorsAC = (investors: any) => ({ type: UPDATE_INVESTORS, investors });

function buildQueryString(params: {
  page?: number;
  limit?: number;
  linkToken?: string;
  filter?: string;
  query?: string;
  sortField?: string;
  direction?: string;
  mmId?: number;
}): string {
  let queryString = `?page=${params.page}`;

  if (params.limit) queryString += `&limit=${params.limit}`;
  if (params.filter) queryString += `&filter=${params.filter}`;
  if (params.query) queryString += `&query=${params.query}`;
  if (params.sortField) queryString += `&sortField=${params.sortField}`;
  if (params.direction) queryString += `&direction=${params.direction}`;
  if (params.mmId) queryString += `&masterId=${params.mmId}`;
  if (params.linkToken) queryString += `&linkToken=${params.linkToken}`;

  return queryString;
}

function initializeLocalStorage() {
  if (!LocalStorageUtils.localStorageKeyExists('investors')) {
    LocalStorageUtils.putValuesToLocalStorage('investors', [
      'id=true',
      'mtLogin=true',
      'name=true',
      'currency=true',
      'balance=true',
      'credit=true',
      'equity=true',
      'profit=true',
      'margin=true',
      'masterId=true',
      'mmMtServerType=true',
      'mmMtServer=true',
      'mmMtLogin=true',
      'masterName=true'
    ]);
  }
}

function fetchInvestorsData(api: string, queryString: string, dispatch: Dispatch) {
  dispatch(setLoadingStatus(true));
  dispatch(setLoadingStatusConnectedInvestors(true));

  fetch(`${api}/investors${queryString}`, headersBuilder(Method.GET))
    .then((response: Response) => Errors.checkResponseForErrors(response, api))
    .then((data: any) => dispatch(fetchInvestors(data.Data, data.PaginationInfo)))
    .catch((error: Error) => {
      Errors.throwErrorMessage(error);
    })
    .finally(() => {
      dispatch(setLoadingStatusConnectedInvestors(false));
      dispatch(setLoadingStatus(false));
    });
}

function fetchInvestorsAsync(
  api: string,
  linkToken?: string,
  filter?: boolean,
  mmId?: number,
  page?: number,
  limit?: number,
  query?: string,
  sortField?: string,
  direction?: string
): (dispatch: Dispatch) => void {

  initializeLocalStorage();

  const queryString = buildQueryString({
    page,
    limit,
    linkToken,
    filter: filter ? 'disabled' : undefined,
    query,
    sortField,
    direction,
    mmId
  });

  return (dispatch: Dispatch) => {
    fetchInvestorsData(api, queryString, dispatch);
  };
}


export function fetchInvestorsListAdminAsync(
  api: string,
  linkToken?: string,
  showInactive?: boolean,
  showTest?: boolean,
  mmId?: number,
  page?: number,
  limit?: number,
  query?: string,
  sortField?: string,
  direction?: string
): (dispatch: Dispatch) => void {

  initializeLocalStorage();

  const queryString = buildQueryString({
    page,
    limit,
    linkToken,
    filter: showInactive ? 'disabled' : showTest ? 'test' : undefined,
    query,
    sortField,
    direction,
    mmId
  });

  return (dispatch: Dispatch) => {
    fetchInvestorsData(api, queryString, dispatch);
  };
}


export default fetchInvestorsAsync;
