import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import Errors from '../../../shared/validation/errors';
import { IInvestorsMassAction, IInvestorsMassAttach } from '../../../types/investorTypes';

import headersBuilder, { Method } from '../../../utils/request';
import setLoadingStatus from '../setLoadingStatus';

export const FETCH_MASS_ATTACH = 'FETCH_MASS_ATTACH';
export const FETCH_MASS_DEACTIVATION = 'FETCH_MASS_DEACTIVATION';
export const FETCH_MASS_DETACH = 'FETCH_MASS_DETACH';

export type MassAtachAction = {
  type: string;
  massAttach: IInvestorsMassAttach;
};

export type MassDetachAction = {
  type: string;
  massDetach: IInvestorsMassAction;
};

export type MassDeactivationAction = {
  type: string;
  massDeactivation: IInvestorsMassAction;
};


const fetchMassAttach = (massAttach: IInvestorsMassAttach): MassAtachAction => {
  return {
    type: FETCH_MASS_ATTACH,
    massAttach
  };
};

const fetchMassDetach = (massDetach: IInvestorsMassAction): MassDetachAction => {
  return {
    type: FETCH_MASS_DETACH,
    massDetach
  };
};

const fetchMassDeactivation = (massDeactivation: IInvestorsMassAction): MassDeactivationAction => {
  return {
    type: FETCH_MASS_DEACTIVATION,
    massDeactivation
  };
};

const massAttachInvestors = (api: string, request: IInvestorsMassAttach) => {
  return (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus(true));
    fetch(`${api}/investors/attach`, headersBuilder(Method.POST, request))
    .then((response) => Errors.checkResponseForErrors(response, api))
    .then((massAttach: any) => {
      dispatch(fetchMassAttach(massAttach));
        toast.success('Request to server is sent successfully, check details of connection in response', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
    })
    .catch((error) => Errors.throwErrorMessage(error))
    .finally(() => dispatch(setLoadingStatus(false)));
  }
};

export const massDetachInvestors = (api: string, request: IInvestorsMassAction) => {
  return (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus(true));
    fetch(`${api}/investors/detach`, headersBuilder(Method.POST, request))
    .then((response) => Errors.checkResponseForErrors(response, api))
    .then((massDetach: any) => {
      dispatch(fetchMassDetach(massDetach));
        toast.success('Request to server is sent successfully, check details of connection in response', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
    })
    .catch((error) => Errors.throwErrorMessage(error))
    .finally(() => dispatch(setLoadingStatus(false)));
  }
};

export const deactivateMassInvestors = (api: string, request: {}) => {
  return (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus(true));
    fetch(`${api}/investors/deactivate`, headersBuilder(Method.POST, request))
    .then((response) => Errors.checkResponseForErrors(response, api))
    .then((massDeactivation: any) => {
      dispatch(fetchMassDeactivation(massDeactivation));
        toast.success('Request to server is sent successfully, check details of connection in response', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
    })
    .catch((error) => Errors.throwErrorMessage(error))
    .finally(() => dispatch(setLoadingStatus(false)));
  }
};




export default massAttachInvestors;
