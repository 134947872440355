import React from 'react';
import { Navbar, Row, Col } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Icons from '../../icons/icons';
import { Investor } from '../../types/investorTypes';
import { PAMMState } from '../../redux/reducers/rootReducer';
import getCurrencySymbol from '../../utils/currency';
import { spaceSeparatedThousands } from '../../utils/number';
import { getEquityOverInvestments, getEquityOverInvestmentsCredit } from '../../utils/value';
import { VisibilityExtendedToggle } from "../../types/toggles";

const SoMobile: React.FC = (): JSX.Element => {
  const intl = useIntl();

  const currenciesConfig: Record<string, string> = useSelector((state: PAMMState) => state.configReducer.currency);
  const sharedConfig = useSelector((state: PAMMState) => state.configReducer.sharedConfig);

  const investor: Investor = useSelector((state: PAMMState) => state.investorReducer.investor);

  const currency = getCurrencySymbol(investor?.Currency, currenciesConfig);

  const getSoSlTpValue = (orderType: string): string | number => {
    switch (orderType) {
      case 'so':
        if (investor?.SoMode === 'Off') {
          return intl.formatMessage({ id: 'investorDetails.soSlTpInformation.disabled' });
        } if (investor?.SoMode === 'Money') {
          return `${currency} ${spaceSeparatedThousands(investor?.So)} (${investor?.SoPercent}%)`
        } if (investor?.SoMode === 'Percent') {
          return `${investor?.SoPercent}% (${currency}${spaceSeparatedThousands(investor?.So)})`;
        } if (investor?.SoMode === 'FromMM') {
          return intl.formatMessage({ id: 'investorDetails.soSlTpInformation.fromMM' });
        }
        break;
      default:
        break;
    }

    return intl.formatMessage({ id: 'investorDetails.soSlTpInformation.disabled' });
  };


  const creditShow =  sharedConfig.CountCreditInRiskControl ? <FormattedMessage id="investorDetails.modal.updateBalance.creditIsIncluded.label"/> :   <FormattedMessage id="investorDetails.modal.updateBalance.creditIsNotIncluded.label"/>

  return (
    <>
      {/* Top bar */}
      <Navbar
        variant="light"
        bg="light"
        fixed="top"
        style={{height: '4rem'}}
        className="shadow-sm"
      >
        <div className="font-weight-bold mx-2">
          <Link to="/dashboard">
            <span>
              {Icons.ArrowLeftIcon('black', 'sm')}
            </span>
          </Link>
          <span className="ml-4">
            <FormattedMessage id="investorDetails.mobile.editSlTp.stopOut" />
          </span>
        </div>
      </Navbar>

      <div style={{width: '83vw', paddingTop: '5rem'}} className="justify-content-center">
        {/* Stop Out */}
        {(investor?.AccountOptions?.InvestorOpenOrdersTab === VisibilityExtendedToggle.Show
          || investor?.AccountOptions?.InvestorOpenOrdersTab === VisibilityExtendedToggle.OnlyProfit) && (
        <Row className="pb-3" style={{ fontSize: 15 }}>
          <Col>
            <span className="font-weight-bold">
              <FormattedMessage id="investorDetails.mobile.editSlTp.stopOut" />
            </span>
          </Col>

          <Col>
            {getSoSlTpValue('so')}
          </Col>
        </Row>
        )}
        {/* Investments */}
        <Row className="pb-3" style={{ fontSize: 15 }}>
          <Col>
            <span className="font-weight-bold">
              <FormattedMessage id="slTpMobile.investments" />
            </span>
          </Col>

          <Col>
            {`${investor.Investments} ${getCurrencySymbol(investor.Currency, currenciesConfig)}`}
          </Col>
        </Row>

        {/* Equity */}
        {(investor?.AccountOptions?.InvestorOpenOrdersTab === VisibilityExtendedToggle.Show
          || investor?.AccountOptions?.InvestorOpenOrdersTab === VisibilityExtendedToggle.OnlyProfit) && (
        <Row className="mb-4" style={{ fontSize: 15 }}>
          <Col>
            <span className="font-weight-bold">
              <FormattedMessage id="slTpMobile.equity" />
            </span>{' '}
            {creditShow}:
          </Col>

          <Col>
            <span className="mr-1">
              {`${investor.EquityNoCredit} ${getCurrencySymbol(investor.Currency, currenciesConfig)}`}
            </span>

            <span>
              {`(${sharedConfig.CountCreditInRiskControl ? getEquityOverInvestmentsCredit(investor) : getEquityOverInvestments(investor)} %)`}
            </span>
          </Col>
        </Row>
        )}
      </div>
    </>
  );
};

export default SoMobile;
